import React, { useState, useEffect } from 'react';
import './../../Cici.css';
import ReactMarkdown from 'react-markdown';
import './Banner.css' ;

const Banner = ({ title, subtitle, onStateChange, active}) => {
  const [isOpen, setIsOpen] = useState(active);


  const bannerStyle = {
     background: '#fff0cc',
      color: '#fff',
      position: 'absolute',
      top: '48px',
      zIndex: '1',
      width: '100%',
      paddingRight: '20px',
      paddingTop: '20px',
      paddingLeft: '20px'
  }

    
  useEffect(() => {
  }, [isOpen, subtitle]);

  useEffect(() => {}, [isOpen, subtitle]);

  useEffect(() => {
    setIsOpen(active);
  }, [active]);

  useEffect(() => {
    onStateChange(isOpen);
  }, [isOpen, onStateChange]);

  const closeIconStyle = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    cursor: 'pointer',
    color: 'black'
  };

  const bannerTitle = {
    fontSize: '1.5rem',
    textAlign: 'left',
    color: 'black',
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && (
        <div id="banner" style={bannerStyle}>
          <span style={closeIconStyle} onClick={handleClose}>
            X
          </span>
          <h1 style={bannerTitle}>{title}</h1>
          <ReactMarkdown className="subTitle" children={subtitle} />
        </div>
      )}
    </>
  );
};

export default Banner;